const Footer = () => {
  return (
    <footer className="bg-secondary text-white py-10">
      <img src="/WWR_Logo_2.png" alt="logo" className="w-56 mx-auto" />
      {/* Divider */}
      <div className="mt-10 border-t border-gray-700 pt-6 flex flex-col lg:flex-row relative gap-5">
        <div className="mx-auto">
          <a className="underline text-gray-400 text-sm text-center block" href="/privacy-policy">Privacy Policy</a>
          <p className="text-center text-gray-400 text-sm mt-2">
            &copy; {new Date().getFullYear()} Wounded Warrior Roofing. All rights reserved.
          </p>
        </div>
        <div className="lg:absolute lg:left-10">
          <p className="underline text-gray-400 text-sm lg:text-left text-center block pb-2">Contact Us</p>
            <a href="mailto:office@wwroofingok.com" className="text-gray-400 text-sm text-center lg:text-left block">office@wwroofingok.com</a>
            <a href="tel:+19183484603" className="text-gray-400 text-sm text-center lg:text-left block">918-348-4603</a>
        </div>
      </div>
    </footer >
  );
};

export default Footer;
